import { useToken } from '@chakra-ui/react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Shape } from 'konva/lib/Shape';
import { Image as KonvaImage } from 'konva/lib/shapes/Image';
import { Transformer as KonvaTransformer } from 'konva/lib/shapes/Transformer';
import { Stage } from 'konva/lib/Stage';
import React from 'react';
import { Image as ReactKonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';

import { BoundingBox } from '../../types/api-types';

interface ResizeableImageProps {
    boundingBox: BoundingBox,
    onChange(newAttr: BoundingBox, shape?: Shape | Stage): void,
    onChangeEnd(newAttr: BoundingBox, shape?: Shape | Stage): void,
    imageSrc: string,
    opacity?: number,
    borderStrokeWidth?: number,
    anchorSize?: number
}

export default function ResizeableImage({
    boundingBox,
    onChange,
    onChangeEnd,
    imageSrc,
    opacity,
    borderStrokeWidth,
    anchorSize,
}: ResizeableImageProps) {
    const [primaryColor] = useToken('colors', ['primary.500']);
    const [imgObj] = useImage(imageSrc);

    const imageRef = React.useRef<KonvaImage>(null);
    const trRef = React.useRef<KonvaTransformer>(null);

    // ---- Get Stage container from Event and change the cursor style value ----
    const changeContainerCursor = (e: KonvaEventObject<MouseEvent>, cursorValue: string) => {
        const container = e.target?.getStage()?.container();
        if (container) {
            container.style.cursor = cursorValue;
        }
    };

    // ---- Stop propagation of Event from Konva Event object ----
    const stopKonvaEventPropagation = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.stopPropagation();
    };

    const onDrag = (e: KonvaEventObject<DragEvent>) => {
        onChange(
            {
                ...boundingBox,
                x: e.target.x(),
                y: e.target.y(),
            },
            e.target,
        );
    };

    const onDragEnd = (e: KonvaEventObject<DragEvent>) => {
        onChangeEnd(
            {
                ...boundingBox,
                x: e.target.x(),
                y: e.target.y(),
            },
            e.target,
        );
    };

    const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
        changeContainerCursor(e, 'grab');
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        changeContainerCursor(e, 'crosshair');
    };

    const onMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        changeContainerCursor(e, 'grab');
    };

    const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        stopKonvaEventPropagation(e);
        changeContainerCursor(e, 'grabbing');
    };

    // ---- Handle at the end of a scaling or rotation ----
    const onTransformEnd = () => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = imageRef.current;

        if (!node) {
            return;
        }

        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        onChangeEnd({
            height: Math.max(node.height() * scaleY),

            rotation: node.rotation(),

            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            x: node.x(),
            y: node.y(),
        },
        node);
    };

    React.useEffect(() => {
        if (trRef.current && imageRef.current) {
            // we need to attach transformer manually
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer()?.batchDraw();
        }
    }, []);

    return (
        <React.Fragment>
            <ReactKonvaImage
                draggable
                height={boundingBox.height || 0}
                image={imgObj}
                onDragEnd={onDragEnd}
                onDragMove={onDrag}
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onTransformEnd={onTransformEnd}
                opacity={opacity}
                ref={imageRef}
                rotation={boundingBox.rotation}
                width={boundingBox.width || 0}
                x={boundingBox.x}
                y={boundingBox.y}
            />

            <Transformer
                anchorSize={anchorSize || 10}
                anchorStroke={primaryColor}
                borderDash={[5, 5]}
                borderStroke={primaryColor}
                borderStrokeWidth={borderStrokeWidth || 1}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                        return oldBox;
                    }

                    return newBox;
                }}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                flipEnabled={false}
                onMouseDown={stopKonvaEventPropagation}
                ref={trRef}
                rotateAnchorCursor="default"
            />
        </React.Fragment>
    );
}
