// ---- Get the BoundingBox bottom right corner from BoundingBox Infos ----
export const getBottomRightCorner = (topLeftCorner: { x: number, y: number }, width: number, height: number, angleInRadians: number) => {
    // Calculate the relative position of the bottom right corner (width, height) rotated by angle
    const deltaX = width * Math.cos(angleInRadians) - height * Math.sin(angleInRadians);
    const deltaY = width * Math.sin(angleInRadians) + height * Math.cos(angleInRadians);

    // Calculate the absolute position of the bottom right corner
    const bottomRightCorner = {
        x: topLeftCorner.x + deltaX,
        y: topLeftCorner.y + deltaY,
    };

    return bottomRightCorner;
};

// ---- Apply a rotation according to the center to a point ----
export const getRotatedPoint = (x: number, y: number, cx: number, cy: number, angleRad: number) => {
    const xOrigin = x - cx;
    const yOrigin = y - cy;

    const rotatedX = xOrigin * Math.cos(-angleRad) - yOrigin * Math.sin(-angleRad);
    const rotatedY = xOrigin * Math.sin(-angleRad) + yOrigin * Math.cos(-angleRad);

    return { x: rotatedX + cx, y: rotatedY + cy };
};

// Utility to check if a point is inside a rotated rectangle
export const isPointInRotatedRectangle = (
    pointX: number,
    pointY: number,
    rectX: number, // Top-left corner x
    rectY: number, // Top-left corner y
    rectWidth: number,
    rectHeight: number,
    rectAngle: number, // Rotation in radians
): boolean => {
    // Step 1: Translate the point to the rectangle's origin
    const translatedX = pointX - rectX;
    const translatedY = pointY - rectY;

    // Step 2: Reverse rotate the point
    const cosAngle = Math.cos(-rectAngle);
    const sinAngle = Math.sin(-rectAngle);
    const rotatedX = translatedX * cosAngle - translatedY * sinAngle;
    const rotatedY = translatedX * sinAngle + translatedY * cosAngle;

    // Step 3: Check if the rotated point is inside the rectangle's bounds
    return (
        rotatedX >= 0
        && rotatedX <= rectWidth
        && rotatedY >= 0
        && rotatedY <= rectHeight
    );
};

// ---- Get pos of point on image to the rect origin ----
export const imagePointToRectLocal = (
    pointX: number,
    pointY: number,
    rectX: number, // Top-left corner x
    rectY: number, // Top-left corner y
    rectWidth: number,
    rectHeight: number,
    rectAngle: number, // Rotation in radians
) => {
    // Step 1: Translate the point to the rectangle's origin
    const translatedX = pointX - rectX;
    const translatedY = pointY - rectY;

    // Step 2: Reverse rotate the point
    const cosAngle = Math.cos(-rectAngle);
    const sinAngle = Math.sin(-rectAngle);
    const rotatedX = translatedX * cosAngle - translatedY * sinAngle;
    const rotatedY = translatedX * sinAngle + translatedY * cosAngle;

    // ---- Get percentage compare to BB width/height ----
    return { x: (rotatedX * 100) / rectWidth, y: (rotatedY * 100) / rectHeight };
};
