import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

async function startApp() {
    // Record all sessions on localhost, staging stacks and preview URLs
    if (!window.location.hostname.match('generation.veesual.ai') && process.env.REACT_APP_METICULOUS_TOKEN) {
        // Start the Meticulous recorder before you initialise your app.
        // Note: all errors are caught and logged, so no need to surround with try/catch
        await tryLoadAndStartRecorder({
            isProduction: false,
            recordingToken: process.env.REACT_APP_METICULOUS_TOKEN,
        });
    }

    // Initalise app after the Meticulous recorder is ready, e.g.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}

startApp().catch((err) => console.error(err));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
