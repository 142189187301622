import { InfoIcon } from '@chakra-ui/icons';
import { Box, HStack, Image, ImageProps, Switch, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import ImageWithError from '../../components/ImageWithError';
import InfoWithTooltip from '../../components/InfoWithTooltip';
import Keypoint from '../../components/Keypoint';
import ModelIdentityBanner from '../../components/ModelIdentityBanner';
import ZoomSlider from '../../components/sliders/ZoomSlider';
import { BoundingBox, KeypointType, ModelIdentity, Pose, WarpBoundingBoxRef } from '../../types/api-types';
import { COMMON_LOCALES, PREPROCESSING_LOCALES, WARP_EDIT_LOCALES, WARP_LOCALES } from '../../utils/constants';
import useFeedbackContextHelper from '../../utils/feedback-context-helper-hook';
import resizeImage from '../../utils/image';
import WarpBoundingBox from './WarpBoundingBox';

const ERASE_CURSOR = 'url(/assets/illustrations/remove-50.png) 25 25, pointer';
const KEYPOINT_SIZE = 8; // Size in px

let hasMouseDown = false;

// ---- Image used to superpose one on each other in the warp container ----
const ImagePiece = React.forwardRef<HTMLImageElement, ImageProps>(
    (props, ref) => <ImageWithError
        maxHeight="100%"
        objectFit="contain"
        position="absolute"
        ref={ref}
        {...props} />,
);

interface WarpGridItemProps {
    srcs: string[],
    blockEdit?: boolean,
    disableDrag?: boolean,
    eraseMode?: boolean,
    extras?: string[],
    handleErase?(e: React.MouseEvent): void,
    handleMouseUp?(e: React.MouseEvent): void,
    hoveredKp?: number | null,
    isImageDrag?: boolean,
    kpList: KeypointType[],
    onWarpClick?(e: React.MouseEvent, imageId: string): void,
    opacitySource?: string,
    opacityValue?: number,
    removeKp?(kpIndex: number): void,
    setHoveredKp?(kpIndex: number | null): void,
    setIsKpDrag?(newVal: boolean): void,
    setIsImageDrag?(newVal: boolean): void,
    updateKp?(index: number, newPos: KeypointType, kpType: string): void,
    visibleValues?: string[],
    warpType: string,
    noEdit?: boolean,
    title?: string,
    info?: string,
    modelIdentity?: ModelIdentity,
    guide?: Pose
    moveAllKpMode?: boolean
    garmentcuts?: { [key: string]: string }
    onGarmentcutsChange?(newVal: boolean): void
    maskSrcs?: { base: string, masks: string[] }
    boundingBoxProps?: {
        isBoundingBoxMode: boolean,
        onBoundingBoxChanged?(newBB: BoundingBox): void,
        garmentSrc: string,
        backgroundSrc: string,
        initBoundingBox?: BoundingBox,
        warpSrc?: string,
        isLoading?: boolean,
        showWarpSrc?: boolean,
    }
    modelOpenposeKps?: KeypointType[] | null
}

const WarpGridItem = React.forwardRef<WarpBoundingBoxRef, WarpGridItemProps>((props, ref) => {
    const { t } = useTranslation([PREPROCESSING_LOCALES, WARP_LOCALES]);

    const {
        srcs,
        eraseMode,
        extras,
        handleErase,
        handleMouseUp,
        isImageDrag,
        kpList,
        onWarpClick,
        opacitySource,
        opacityValue,
        removeKp,
        setIsKpDrag,
        setIsImageDrag,
        updateKp,
        visibleValues,
        warpType,
        blockEdit,
        disableDrag,
        noEdit,
        title,
        info,
        modelIdentity,
        guide,
        moveAllKpMode,
        garmentcuts,
        onGarmentcutsChange,
        maskSrcs,
        boundingBoxProps,
        modelOpenposeKps,
    } = props;

    useFeedbackContextHelper({ imageSrc: srcs, kpList, label: t('garments.warp', { ns: WARP_LOCALES }) });

    const [imgOverlaySize, setImgOverlaySize] = useState<{ height: number, width: number }>({ height: 0, width: 0 });
    const [zoomScale, setZoomScale] = useState<number>(1);
    const [isGuideLocked, setIsGuideLocked] = useState<boolean>(true);
    const [isGarmentcutsLocked, setIsGarmentcutsLocked] = useState<boolean>(true);

    const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

    const firstImgRef = useRef<HTMLImageElement>(null);

    const imgCursorValue = useMemo(() => {
        if (noEdit) {
            return 'default';
        }

        return (eraseMode && !blockEdit ? ERASE_CURSOR : 'crosshair');
    }, [eraseMode, blockEdit, noEdit]);

    // ---- Update the inputZoomValue from scale and validate the value ----
    const updateZoomValues = (scale: number) => {
        setZoomScale(scale);
    };

    const handleKpDragStart = () => {
        if (setIsKpDrag) {
            setIsKpDrag(true);
        }
    };

    const handleKpDragEnd = (index: number, newPos: KeypointType, kpType: string) => {
        if (updateKp && setIsKpDrag) {
            updateKp(index, newPos, kpType);
            setIsKpDrag(false);
        }
    };

    const handleResize = () => {
        const imgHtmlElem = firstImgRef.current;

        if (!imgHtmlElem) {
            return;
        }

        setImgOverlaySize({ height: imgHtmlElem.clientHeight, width: imgHtmlElem.clientWidth });
    };

    const handleHover = (e: React.MouseEvent, index: number) => {
        // ---- We get the kp twin and set his color acording to current event type (leave or enter) ----
        const kpTwin = document.getElementById(`kp_${warpType === 'source' ? 'destination' : 'source'}_${index}`);
        if (kpTwin) {
            kpTwin.style.color = e.type === 'mouseleave' ? '' : 'blue';
        }
    };

    const getElementsCrosshair = () => ({
        box: document.getElementById(`${warpType}_coord_box`),
        horizontal: document.getElementById(`${warpType}_horizontal_crosshair_line`),
        vertical: document.getElementById(`${warpType}_vertical_crosshair_line`),
    });

    const handleMouseMove = (e: MouseEvent) => {
        const { horizontal, vertical, box } = getElementsCrosshair();
        const container = document.getElementById(`${warpType}_container`);

        if (!horizontal || !vertical || !container || !box) {
            return;
        }

        const rect = container.getBoundingClientRect();

        const x = e.clientX - rect.x;
        const y = e.clientY - rect.y;

        vertical.style.left = `${x}px`;
        horizontal.style.top = `${y}px`;
        box.style.top = `${y + box.clientHeight + (e.clientY > (rect.bottom - 35) ? -60 : 0)}px`;
        box.style.left = `${x + 8}px`;
        box.innerHTML = `x: ${x.toFixed(0)}, y: ${y.toFixed(0)}`;
    };

    const handleMouseLeave = () => {
        const { horizontal, vertical, box } = getElementsCrosshair();

        if (!horizontal || !vertical || !box) {
            return;
        }

        horizontal.style.display = 'none';
        vertical.style.display = 'none';
        box.style.display = 'none';
    };

    const handleMouseEnter = () => {
        const { horizontal, vertical, box } = getElementsCrosshair();

        if (!horizontal || !vertical || !box) {
            return;
        }

        horizontal.style.display = 'block';
        vertical.style.display = 'block';
        box.style.display = 'block';
    };

    const handleImageMouseDown = (e: React.MouseEvent) => {
        // ---- Set var used to prevent click handeling if we started erasemode ----
        hasMouseDown = true;
        if (handleErase) {
            handleErase(e);
        }
    };

    const handleImageMouseUp = (e: React.MouseEvent) => {
        if (handleMouseUp) {
            handleMouseUp(e);
        }
    };

    const handleImageOnClick = (e: React.MouseEvent) => {
        if (!eraseMode && !hasMouseDown && onWarpClick) {
            onWarpClick(e, warpType);
        }

        // ---- If we prevented the click handling we set the hasMouseDown back to false ----
        if (hasMouseDown) {
            hasMouseDown = false;
        }
    };

    const handleGarmentcutsChange = () => {
        setIsGarmentcutsLocked(!isGarmentcutsLocked);

        if (onGarmentcutsChange) {
            onGarmentcutsChange(!isGarmentcutsLocked);
        }
    };

    useEffect(() => {
        if (!firstImgRef.current) {
            return undefined;
        }

        // ---- Observe firstImgRef for resize calls ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(firstImgRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [firstImgRef.current]);

    useEffect(() => {
        const container = document.getElementById(`${warpType}_container`);

        if (!container) {
            return undefined;
        }

        // ---- Mouse handler ----
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mouseenter', handleMouseEnter);

        return () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseleave', handleMouseLeave);
            container.removeEventListener('mouseenter', handleMouseEnter);
        };
    }, []);

    return (
        <VStack height="100%" spacing={0} width="100%">
            <VStack
                display="flex"
                height="100%"
                id={`${warpType}_container`}
                justifyContent="center"
                position="relative"
                width="100%"
            >
                {
                    title && <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                        <HStack justifyContent="center">
                            <Text fontWeight="bold">{title}</Text>
                            {info && <InfoWithTooltip tooltipLabel={info} />}
                        </HStack>
                    </HStack>
                }

                {
                    !noEdit && <>
                        <div
                            id={`${warpType}_horizontal_crosshair_line`}
                            style={{
                                borderTop: '1px dotted',
                                display: 'none',
                                height: 0,
                                pointerEvents: 'none',
                                position: 'absolute',
                                width: '100%',
                                zIndex: 1,
                            }}
                        />
                        <div
                            id={`${warpType}_vertical_crosshair_line`}
                            style={{
                                borderLeft: '1px dotted',
                                display: 'none',
                                height: '100%',
                                pointerEvents: 'none',
                                position: 'absolute',
                                width: 0,
                                zIndex: 1,
                            }}
                        />
                        <div
                            id={`${warpType}_coord_box`}
                            style={{
                                backgroundColor: 'var(--chakra-colors-gray-100)',
                                border: '1px solid var(--chakra-colors-primary-500)',
                                display: 'none',
                                float: 'left',
                                fontSize: 12,
                                height: '20px',
                                overflowY: 'hidden',
                                padding: '0 2px',
                                position: 'absolute',
                                whiteSpace: 'nowrap',
                                zIndex: 1,
                            }}
                        />
                    </>
                }

                <TransformWrapper
                    doubleClick={{ disabled: true }}
                    onPanning={() => { if (!isImageDrag && setIsImageDrag) { setIsImageDrag(true); } }}
                    onZoom={(newRef) => { setZoomScale(newRef.state.scale); }}
                    panning={{ disabled: disableDrag, velocityDisabled: true }}
                    ref={transformComponentRef}
                    wheel={{
                        step: 0.05,
                    }}
                >
                    <TransformComponent
                        contentStyle={{ alignItems: 'center', height: 'calc(100% - 8px)', justifyContent: 'center', width: '100%' }}
                        wrapperStyle={{ height: '100%', width: '100%' }}
                    >
                        {
                            srcs.map((src, index) => (
                                <ImagePiece
                                    cursor={imgCursorValue}
                                    id={warpType}
                                    key={src}
                                    onClick={handleImageOnClick}
                                    onMouseDown={eraseMode ? handleImageMouseDown : undefined}
                                    onMouseMove={eraseMode ? handleErase : undefined}
                                    onMouseUp={handleImageMouseUp}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    pointerEvents="auto !important"
                                    ref={index === 0 ? firstImgRef : null}
                                    src={resizeImage(src, { width: 800 })}
                                />
                            ))
                        }
                        {
                            maskSrcs
                            && <Image
                                css={{
                                    maskComposite: 'subtract',
                                    maskImage: [maskSrcs.base, ...maskSrcs.masks].map((maskSrc) => `url(${maskSrc})`).join(','),
                                    maskSize: '100%',
                                }}
                                maxHeight="100%"
                                objectFit="contain"
                                position="absolute"
                                src={maskSrcs.base}
                            />
                        }

                        {
                            extras && extras.map((src) => (
                                <Image
                                    key={src}
                                    maxHeight="100%"
                                    objectFit="contain"
                                    position="absolute"
                                    src={resizeImage(src, { width: 800 })}
                                />
                            ))
                        }
                        {
                            isGarmentcutsLocked && garmentcuts && Object.keys(garmentcuts).map((localKey) => (
                                <Image
                                    filter="grayscale(1)"
                                    key={localKey}
                                    maxHeight="100%"
                                    objectFit="contain"
                                    opacity={0.7}
                                    position="absolute"
                                    src={resizeImage(garmentcuts[localKey], { width: 800 })}
                                />
                            ))
                        }
                        {
                            isGuideLocked && guide?.transparent?.garment_image_url && <Image
                                filter="
                                brightness(0) saturate(100%) invert(82%) sepia(60%) saturate(2897%) hue-rotate(115deg) brightness(108%) contrast(112%)
                                "
                                maxHeight="100%"
                                objectFit="contain"
                                opacity={0.5}
                                position="absolute"
                                src={resizeImage(`${guide.transparent.garment_image_url}&grid=white`, { width: 800 })}
                            />
                        }
                        {
                            opacitySource && <Image
                                maxHeight="100%"
                                objectFit="contain"
                                opacity={opacityValue}
                                position="absolute"
                                src={resizeImage(opacitySource, { width: 800 })}
                            />
                        }
                        <Box
                            display={boundingBoxProps && boundingBoxProps.isBoundingBoxMode ? 'none' : undefined}
                            height={`${imgOverlaySize.height}px`}
                            id={`${warpType}_kp_container`}
                            pointerEvents="none"
                            position="relative"
                            width={`${imgOverlaySize.width}px`}
                        >
                            {
                                kpList.map((kp, index) => (
                                    <Keypoint
                                        currentZoomScale={zoomScale}
                                        iconProps={{
                                            _hover: (!noEdit
                                                ? {
                                                    color: 'blue',
                                                }
                                                : {}),
                                            color: moveAllKpMode ? 'blue' : undefined,
                                            display: (!visibleValues || visibleValues.includes(kp.type)) ? 'block' : 'none',
                                            h: `${KEYPOINT_SIZE}px`,
                                            id: `kp_${warpType}_${index}`,
                                            onMouseEnter: (e) => handleHover(e, index),
                                            onMouseLeave: (e) => handleHover(e, index),
                                            pointerEvents: blockEdit || eraseMode ? 'none' : 'auto',
                                            w: `${KEYPOINT_SIZE}px`,
                                        }}
                                        imageId={warpType}
                                        initX={kp.x}
                                        initY={kp.y}
                                        key={index + kp.x.toString() + kp.y.toString()}
                                        onDoubleClick={() => { if (removeKp) { removeKp(index); } }}
                                        onDragEnd={(newPos) => handleKpDragEnd(index, newPos, warpType)}
                                        onDragStart={handleKpDragStart}
                                        type={kp.type}
                                    />
                                ))
                            }
                        </Box>

                        {
                            boundingBoxProps && boundingBoxProps.isBoundingBoxMode
                            && <>
                                <WarpBoundingBox
                                    {...boundingBoxProps}
                                    garmentOpacity={opacityValue}
                                    imageElement={firstImgRef.current}
                                    ref={ref}
                                    zoomScale={zoomScale}
                                />
                                {
                                    modelOpenposeKps && !boundingBoxProps.showWarpSrc && <Box
                                        height={`${imgOverlaySize.height}px`}
                                        id={`${warpType}_kp_container`}
                                        pointerEvents="none"
                                        position="relative"
                                        width={`${imgOverlaySize.width}px`}
                                    >
                                        {
                                            modelOpenposeKps.map((kp, index) => (
                                                <Keypoint
                                                    currentZoomScale={zoomScale}
                                                    iconProps={{
                                                        display: 'block',
                                                        h: `${KEYPOINT_SIZE}px`,
                                                        id: `kp_${warpType}_${index}`,
                                                        onMouseEnter: (e) => handleHover(e, index),
                                                        onMouseLeave: (e) => handleHover(e, index),
                                                        pointerEvents: 'none',
                                                        w: `${KEYPOINT_SIZE}px`,
                                                    }}
                                                    imageId={warpType}
                                                    initX={kp.x}
                                                    initY={kp.y}
                                                    key={index + kp.x.toString() + kp.y.toString()}
                                                    onDoubleClick={() => { if (removeKp) { removeKp(index); } }}
                                                    onDragEnd={(newPos) => handleKpDragEnd(index, newPos, warpType)}
                                                    onDragStart={handleKpDragStart}
                                                    type={kp.type}
                                                />
                                            ))
                                        }
                                    </Box>
                                }
                            </>
                        }
                    </TransformComponent>
                </TransformWrapper>
                <VStack alignItems="center" bottom={0} left={2} position="absolute">
                    {
                        transformComponentRef.current
                        && <ZoomSlider
                            max={800}
                            min={100}
                            transformComponentRef={transformComponentRef.current}
                            updateZoomValues={updateZoomValues}
                            zoomValue={zoomScale}
                        />
                    }
                </VStack>

                <VStack bottom={0} position="absolute" right={2} spacing={2}>
                    {
                        garmentcuts
                        && <Tooltip label={t('tooltip.trim_mask', { ns: WARP_EDIT_LOCALES })} openDelay={500}>
                            <HStack justifyContent="space-between" w="100%">
                                <HStack>
                                    <Text textColor="black">{t('trim_mask', { ns: COMMON_LOCALES })}</Text>
                                    <InfoIcon />
                                </HStack>

                                <Switch
                                    defaultChecked={true}
                                    onChange={handleGarmentcutsChange}
                                    size="lg"
                                />
                            </HStack>
                        </Tooltip>
                    }
                    {
                        guide
                        && <Tooltip label={t('tooltip.guide', { ns: WARP_EDIT_LOCALES })} openDelay={500}>
                            <HStack justifyContent="space-between" w="100%">
                                <HStack>
                                    <Text textColor="black">{t('guide', { ns: COMMON_LOCALES })}</Text>
                                    <InfoIcon />
                                </HStack>
                                <Switch
                                    defaultChecked={true}
                                    onChange={() => setIsGuideLocked(!isGuideLocked)}
                                    size="lg"
                                />
                            </HStack>
                        </Tooltip>
                    }
                </VStack>

            </VStack>
            {modelIdentity && <ModelIdentityBanner
                modelHeight={modelIdentity.height}
                modelSizes={[modelIdentity.garment_size, modelIdentity.garment_size2]}
            />}

        </VStack>

    );
});

export default WarpGridItem;
