import { VStack } from '@chakra-ui/react';
import { Shape } from 'konva/lib/Shape';
import { Stage as KonvaStage } from 'konva/lib/Stage';
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';

import { BoundingBox, WarpBoundingBoxRef } from '../../types/api-types';
import ResizeableImage from './ResizeableImage';

interface WarpBoundingBoxProps {
    backgroundSrc: string,
    garmentOpacity?: number,
    garmentSrc: string,
    warpSrc?: string,
    onBoundingBoxChanged?(newBB: BoundingBox): void,
    initBoundingBox?: BoundingBox,
    showWarpSrc?: boolean,
    zoomScale?: number,
    imageElement?: HTMLImageElement | null,
}

const WarpBoundingBox = React.forwardRef<WarpBoundingBoxRef, WarpBoundingBoxProps>((props, ref) => {
    const {
        backgroundSrc,
        garmentOpacity,
        garmentSrc,
        onBoundingBoxChanged,
        initBoundingBox,
        showWarpSrc,
        zoomScale,
        imageElement,
    } = props;

    const [garmentImageSize, setGarmentImageSize] = useState<{ width: number, height: number }>();
    const [rectangle, setRectangle] = React.useState<BoundingBox>({
        height: 0,
        rotation: 0,
        width: 0,
        x: 16,
        y: 16,
    });

    const containerRef = useRef<HTMLDivElement>(null);

    // ---- Calculate the new Rectangle from a bounding box ----
    const updateRectangleFromBoundingBox = (newBB: BoundingBox) => {
        if (!imageElement) {
            return;
        }
        // ---- Image vars ----
        const imageWidth = imageElement.clientWidth;
        const imageHeight = imageElement.clientHeight;

        // ---- Result rectangle ----
        const newRect: BoundingBox = {
            height: newBB.percentageValues ? imageHeight * newBB.percentageValues.height : newBB.height,
            rotation: newBB.rotation,
            width: newBB.percentageValues ? imageWidth * newBB.percentageValues.width : newBB.width,
            x: newBB.percentageValues ? imageWidth * newBB.percentageValues.x : newBB.x,
            y: newBB.percentageValues ? imageHeight * newBB.percentageValues.y : newBB.y,
        };

        // ---- Update Rectangle state ----
        setRectangle(newRect);
    };

    React.useImperativeHandle(
        ref,
        () => ({
            setBoundingBox(newBB: BoundingBox) {
                updateRectangleFromBoundingBox(newBB);
            },
        }),
    );

    // ---- Get corner pos from bounding box data ----
    const getCorner = (pivotX: number, pivotY: number, diffX: number, diffY: number, angleDeg: number) => {
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);

        /// find angle from pivot to corner
        const newAngle = (angleDeg * Math.PI) / 180 + Math.atan2(diffY, diffX);

        /// get new x and y and round it off to integer
        const x = pivotX + distance * Math.cos(newAngle);
        const y = pivotY + distance * Math.sin(newAngle);

        return { x, y };
    };

    // ---- Get the BoundingBox with pos inside the container ----
    const getInBoundBoundingBox = (newBB: BoundingBox, shape: Shape | KonvaStage): BoundingBox => {
        if (!imageElement) {
            return newBB;
        }

        // ---- Boundingbox vars ----
        const { x, y, width, height } = newBB;
        const angleDeg = newBB.rotation;

        // ---- Bounding Box Corners ----
        const p1 = getCorner(x, y, 0, 0, angleDeg);
        const p2 = getCorner(x, y, width, 0, angleDeg);
        const p3 = getCorner(x, y, width, height, angleDeg);
        const p4 = getCorner(x, y, 0, height, angleDeg);

        // ---- Min and Max ----
        const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
        const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
        const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
        const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

        // ---- Width according to min and max needed because there is rotation ----
        const cornerWidth = maxX - minX;
        const cornerHeight = maxY - minY;

        // ---- Offset betweet the smallest point and the top left corner ----
        const absPos = shape.getAbsolutePosition();
        const offsetX = minX - absPos.x;
        const offsetY = minY - absPos.y;

        // ---- Clone new bounding box ----
        const resultBB = { ...newBB };

        // ---- Image vars ----
        const imageWidth = imageElement.clientWidth;
        const imageHeight = imageElement.clientHeight;

        // ---- Check if out of bound in every direction and adapt the position ----
        if (minX < 0) {
            resultBB.x = -offsetX;
        }
        if (minY < 0) {
            resultBB.y = -offsetY;
        }
        if (minX + cornerWidth > imageWidth) {
            resultBB.x = imageWidth - cornerWidth - offsetX;
        }
        if (minY + cornerHeight > imageHeight) {
            resultBB.y = imageHeight - cornerHeight - offsetY;
        }

        // ---- Update shape pos directly to lock it -----
        if (newBB.x !== resultBB.x || newBB.y !== resultBB.y) {
            shape.setAbsolutePosition({ x: resultBB.x, y: resultBB.y });
        }

        // ---- Calc percentage value needed to handle resize ----
        resultBB.percentageValues = {
            height: resultBB.height / imageHeight,
            width: resultBB.width / imageWidth,
            x: resultBB.x / imageWidth,
            y: resultBB.y / imageHeight,
        };

        return resultBB;
    };

    // ---- Update Bounding box when moving ----
    const handleOnChangeBoundingBox = (newBB: BoundingBox, shape: Shape | KonvaStage) => {
        setRectangle(getInBoundBoundingBox(newBB, shape));
    };

    // ---- Handle when we finished moving the bounding box ----
    const handleOnChangeEndBoundingBox = (newBB: BoundingBox, shape: Shape | KonvaStage) => {
        const handledBB = getInBoundBoundingBox(newBB, shape);
        setRectangle(handledBB);

        if (onBoundingBoxChanged) {
            onBoundingBoxChanged(handledBB);
        }
    };

    const handleResize = () => {
        if (backgroundSrc && containerRef?.current) {
            // ---- We create a new Image object to get the ratio of it ----
            const image = new window.Image();

            image.src = backgroundSrc;
        }
    };

    // ---- Load garmentSrc and update the state with it's size ----
    const handleGarmentImageSize = () => {
        // ---- We create a new Image object to get the ratio of it ----
        const image = new window.Image();

        image.src = garmentSrc;

        // ---- We only handle when the image is loaded ----
        image.onload = () => {
            setGarmentImageSize({ height: image.naturalHeight, width: image.naturalWidth });
        };
    };

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        handleGarmentImageSize();

        // ---- Observe firstImgRef for resize calls ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    /**
     * UseEffect called on garment and background image load and resize
     * We need it in a useEffect because we need both images to be loaded
     * and they update the state when they are loaded or resized
     */
    useEffect(() => {
        // ---- We need both image infos to handle the update of the rectangle
        if (garmentImageSize && imageElement) {
            // ---- Image vars ----
            const imageWidth = imageElement.clientWidth;

            // ---- Base width and height for Bounding box ----
            const width = imageWidth / 2;
            const height = width * (garmentImageSize.height / garmentImageSize.width);

            // ---- Result rectangle ----
            const newRect = {
                ...rectangle,
                height,
                width,
            };

            // ---- If we have an init bounding box and the image Element we calcul the new rectangle value according to those values ----
            if (initBoundingBox && imageElement) {
                updateRectangleFromBoundingBox(initBoundingBox);

                return;
            }

            // ---- Update Rectangle state ----
            setRectangle(newRect);
        }
    }, [garmentImageSize, imageElement]);

    return (
        <VStack h="100%" justifyContent="center" position="absolute" ref={containerRef} w="100%">
            <Stage
                height={imageElement?.clientHeight}
                style={{
                    height: imageElement?.clientHeight,
                    opacity: showWarpSrc ? 0 : 1,
                    pointerEvents: showWarpSrc ? 'none' : undefined,
                    position: 'relative',
                    width: imageElement?.clientWidth,
                }}
                width={imageElement?.clientWidth}
            >
                <Layer id={'boundingbox-layer'}>
                    {
                        garmentImageSize
                        && <ResizeableImage
                            anchorSize={zoomScale ? (Math.max(10 / zoomScale, 5)) : undefined}
                            borderStrokeWidth={zoomScale ? 2 / zoomScale : 1}
                            boundingBox={rectangle}
                            imageSrc={garmentSrc}
                            onChange={handleOnChangeBoundingBox}
                            onChangeEnd={handleOnChangeEndBoundingBox}
                            opacity={garmentOpacity}
                        />
                    }
                </Layer>
            </Stage>
        </VStack>
    );
});

export default WarpBoundingBox;
