import { CloseIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FacetMinMax, Facets, FacetTerms } from '../../types/api-types';
import { PRODUCTS_LOCALES } from '../../utils/constants';
import SearchBar from '../SearchBar';
import { useFiltersContext } from './FiltersContext';
import MultiSelectFilter from './MultiSelectFilter';
import RangeSelectFilter from './RangeSelectFilter';

interface FiltersPanelProps {
    type: string,
    filters?: Facets,
    onSearch?(input: string): void,
    isLoading: boolean
    currentTotalProduct?: number,
}

const FiltersPanel = React.forwardRef<{resetLocalFilters:() => void}, FiltersPanelProps>((
    { type, filters, onSearch, isLoading, currentTotalProduct }, ref,
) => {
    const { t } = useTranslation(PRODUCTS_LOCALES);
    const [localFilters, setLocalFilters] = useState<Facets | null>(null);
    const [initialFilters, setInitialFilters] = useState<Facets | null>(null);
    const rangedSliderRefs = React.useRef<{resetValues(): void}[] | null[]>([]);

    const FiltersContext = useFiltersContext();

    React.useImperativeHandle(
        ref,
        () => ({
            resetLocalFilters() {
                setLocalFilters(null);
                setInitialFilters(null);
                FiltersContext?.deselectAll();
            },
        }),
    );

    useEffect(() => {
        if (filters !== undefined) {
            if (!initialFilters) {
                setInitialFilters(filters);
            }

            // This reduce the size of the ref list if we have less min_max filters when we update
            let numberRangedFilter = 0;
            Object.keys(filters).forEach((keyName) => {
                if (filters[keyName].type === 'min_max') {
                    numberRangedFilter++;
                }
            });
            rangedSliderRefs.current = rangedSliderRefs.current.slice(0, numberRangedFilter);

            return setLocalFilters(filters);
        }

        return setLocalFilters(null);
    }, [filters]);

    const handleOnChange = (newValue: string) => {
        if (onSearch) {
            onSearch(newValue);
        }
    };

    const handleClearFilter = () => {
        FiltersContext?.deselectAll();
        rangedSliderRefs.current?.forEach((sliderRef) => {
            if (sliderRef) {
                sliderRef.resetValues();
            }
        });
    };

    let rangedIndex = 0;

    return (
        <VStack height="full" maxH="full" minW={80} spacing={6} width={80}>
            <VStack align="flex-start" spacing={2} width="full">
                <Text color="gray.400">{t('filters.search.label')}</Text>
                <SearchBar
                    onChange={handleOnChange}
                    placeholder={t('filters.search.placeholder', { productType: type.charAt(0).toUpperCase() + type.slice(1) })}
                />
            </VStack>
            <VStack align="flex-start" overflowY="hidden" spacing={2} width="full">
                <Text color="gray.400">{`${t('grid.search_results')}: ${currentTotalProduct}`}</Text>
                {
                    (FiltersContext?.filters && FiltersContext?.filters.length > 0)
                    && <HStack alignItems="flex-start">
                        <IconButton
                            aria-label='Clear Filters'
                            boxShadow="none"
                            icon={<CloseIcon />}
                            onClick={handleClearFilter}
                            size="xs"
                        />
                        <Wrap>
                            {
                                FiltersContext?.filters.map((filter, index) => (
                                    <WrapItem key={filter.filterValue + index}>
                                        <div>{filter.filterLabel || filter.filterValue}{index + 1 !== FiltersContext?.filters.length ? ',' : ''}</div>
                                    </WrapItem>
                                ))
                            }
                        </Wrap>

                    </HStack>

                }

                <VStack alignItems="flex-start" boxSize="full" overflowY="auto" pb={6}>
                    {
                        localFilters && initialFilters
                        && (Object.keys(localFilters).length < 1 && isLoading === false
                            ? <div>{t('filters.no_filters')}</div>
                            : Object.keys(localFilters).map(
                                (keyName: string) => {
                                    if (!localFilters[keyName].type || localFilters[keyName].type === 'terms') {
                                        return (
                                            <MultiSelectFilter
                                                facet={localFilters[keyName] as FacetTerms}
                                                key={keyName}
                                                keyName={keyName}
                                            />
                                        );
                                    }

                                    if (localFilters[keyName].type === 'min_max' && initialFilters[keyName]) {
                                        return (
                                            <RangeSelectFilter
                                                facet={initialFilters[keyName] as FacetMinMax}
                                                key={keyName}
                                                keyName={keyName}
                                                ref={(el) => { rangedSliderRefs.current[rangedIndex++] = el; }}
                                            />
                                        );
                                    }

                                    return null;
                                },
                            )
                        )
                    }
                </VStack>
            </VStack>
        </VStack>
    );
});

export default FiltersPanel;
